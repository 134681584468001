<template>
  <div class="login">
    <div class="mask"></div>
    <div class="container">
      <!--      <el-row :gutter="20">-->
      <!--        <el-col :offset="12" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">-->
      <!--          <div class="grid-content bg-purple">-->
      <div class="login-info">
        <h2 class="light-color animated fadeInUp">会员登录</h2>
        <h2 class="animated fadeInUp delay01">LOGIN</h2>
        <br>
        <div class="content-login">

          <div class="">
            <!--                    <h3 class="light-color animated fadeInUp">业务范围</h3>-->
            <!--                    <strong>云端技术结合物联网实际场景，为客户提供一站式服务</strong>-->
            <img :src="wxQrcode" alt="" class="qrcode">
            <p>
              使用微信扫码登录
            </p>
          </div>

        </div>
      </div>
      <!--          </div>-->
      <!--        </el-col>-->
      <!--      </el-row>-->

    </div>
  </div>
</template>
<script>


export default {
  name: "Login",
  components: {},
  data() {
    return {
      wxQrcode: "",
      wxLoginCode: "",
      isLogging: false
    };
  },
  created: function () {
    // console.log("CASE:", this.$route);
    // if (this.$route.params != {} && this.$route.params.category != 'all') {
    //   this.categoryID = this.$route.params.category;
    // } else {
    //   this.categoryID = "3"
    // }
    this.initLogin()
  },
  watch: {
    $route: {
      handler: function (val) {
        //console.log(val, oldVal);
        // if (val.params != {} && val.params.category != 'all') {
        //   this.categoryID = "" + val.params.category;
        // } else {
        //   this.categoryID = "3"
        // }
        //this.$forceUpdate();
        this.reload();
      }
    }
  },
  methods: {
    initLogin() {
      let that = this;
      //  window.localStorage.setItem("token", token);
      let token = window.localStorage.getItem("token");
      if (!token) {
        this.$http.post(
            '/index/auth/get_qr_code',
            {},
            {
              headers: {}
            })
            .then(function (response) {
              console.log(response);
              if (response.code === 0) {
                let reData = response.data;
                that.wxQrcode = reData.url;
                that.wxLoginCode = reData.code;
                console.log(that.wxQrcode);
                that.isLogging = true;
                setTimeout(that.updateLogin, 2000);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      } else {
        that.$router.push("/members");
      }
    },
    updateLogin() {
      let that = this;
      //  window.localStorage.setItem("token", token);
      // let token = window.localStorage.getItem("token");
      if (!this.isLogging) {
        return false;
      }
      this.$http.post(
          '/index/auth/check_login',
          {
            code: that.wxLoginCode
          },
          {
            headers: {}
          })
          .then(function (res) {
            console.log(res);
            let token = res.data.token;
            if (res.code === 0) {
              if (res.data.status === 1) {
                that.isLogging = false;
                that.$store.commit('updateToken', token);
                window.localStorage.setItem("token", token);
                that.$router.push("/members/list");
              } else {
                setTimeout(that.updateLogin, 2000);
              }
            } else {
              setTimeout(that.updateLogin, 2000);
            }
          })
          .catch(function (error) {
            console.log(error);
          });

    },
    reload() {
      // // 移除组件
      // this.update = false
      // // 在组件移除后，重新渲染组件
      // // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
      // this.$nextTick(() => {
      //   this.update = true
      // })
    }
  },
  destroyed() {
    this.isLogging = false;
  }
};
</script>

<style scoped lang="scss">
.login {
  position: relative;
  background: url("../assets/bg_login.jpg") no-repeat center;
  background-size: cover;
  min-height: 740px;
  height: calc(100vh - 350px);
}

.login .container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .mask {
  position: absolute;
  left: 50%;
  top: 0;
  width: 50%;
  height: 100%;
  background: rgba(34, 43, 57, 0.9);
}

.content-box {
  height: 35vh;
  overflow: hidden;
}

.content-login {
  padding-right: 20px;
}


.login-info {
  padding: 80px 40px;
  color: #fff;
  position: absolute;
  left: 60%;
  width: 35%;
  top: 50%;
  transform: translate(0, -50%);

  .qrcode {
    width: 250px;
  }

  h2 {
    font-size: 36px;
    line-height: 1;
    margin: 0;
    font-weight: 400;
  }

  hr {
    width: 50%;
    margin-left: 0;
    border-top: none;
    border-bottom: 1px solid #ffffff;
    opacity: 0.5;
    margin-top: 24px;
  }

  .light-color {
    color: #a9abaf;
  }

  p {
    font-size: 16px;
    line-height: 1.8;
  }

  p.light-color {
    line-height: 1.6;
  }

}

@media only screen and (max-width: 991px) {

  .login .mask {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .login {
    min-height: auto;
    height: auto;
  }
  .content-box {
    height: auto;
    //overflow: hidden;
  }
}
</style>
